<template>
  <transition name="fade">
    <div
      v-if="showModel"
      class="
        flex
        main-modal
        fixed
        w-full
        inset-0
        overflow-hidden
        justify-center
        items-start
      "
      style="
        background: rgba(205, 206, 219, 0.6);
        z-index: 1001;
        backdrop-filter: blur(12px);
      "
      @click="modalClose"
    >
      <div
        class="
          content-model
          absolute
          top-1/3
          w-11/12
          md:max-w-md
          mx-auto
          shadow-lg
        "
        @click.stop
      >
        <div class="icon-background pointer-events-none">
          <img src="@/assets/images/illustrationBase.png" />
          <div class="icon">
            <slot name="icon" />
          </div>
        </div>
        <div class="modal-content overflow-y-auto text-center">
          <!--Header-->
          <div class="header">
            <slot name="header" />
          </div>
          <!--Body-->
          <slot />
          <!--Footer-->
          <div class="footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
    },
    showModel: {
      type: Boolean,
      default: true,
    },
    modalClose: {
      type: Function,
    },
  },
  watch: {
    showModel(value) {
      if (value) {
        document.querySelector('body').classList.add('modal-open');
      } else {
        document.querySelector('body').classList.remove('modal-open');
      }
    },
  },
  methods: {
    handleClick() {
      console.log('handle click');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-model {
  max-width: 400px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  padding: 0 50px;
  .icon-background {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    top: -110px;
    img {
      margin: 0 auto;
    }
    .icon {
      position: absolute;
      width: 150px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .modal-content {
    padding-top: 120px;
    max-height: 100vh;
    .header,
    .footer {
      padding: 15px 0;
    }
  }
}
</style>

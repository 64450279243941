import Axios from '../services/axios';

const PATH = 'lender/surveys';

export function getNewestSurvey() {
  return Axios.get(`${PATH}/newest`);
}

export function storeAnswer(surveyId: string, answer: string) {
  return Axios.post(PATH, { surveyId, answer });
}

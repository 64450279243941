
import { defineComponent } from 'vue';
import { getNewestSurvey, storeAnswer } from '@/apis/ApiSurvey';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { getSurvey, setSurvey } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'IntroSlide',
  components: { BaseButton, BaseModal },
  data: () => ({
    openModal: false,
    openModalDeveloping: false,
    survey: null as any,
    isSubmit: false,
    doneSurvey: false,
  }),
  created() {
    this.fetchNewestSurvey();
  },
  mounted() {
    this.openModal = true;

    const survey = getSurvey();
    if (survey) {
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    }
  },
  methods: {
    handleUsingWeb(type: string) {
      if (!this.isSubmit) {
        // {"1":{"value":"Ứng dụng Web","status":true},"2":{"value":"Ứng dụng Android","status":true},"3":{"value":"Ứng dụng IOS","status":true}}
        storeAnswer(this.survey?.id, type);
        this.isSubmit = true;
        setSurvey(this.survey?.id, type);
      }

      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    },
    closeModal(type: string) {
      if (!this.isSubmit) {
        // {"1":{"value":"Ứng dụng Web","status":true},"2":{"value":"Ứng dụng Android","status":true},"3":{"value":"Ứng dụng IOS","status":true}}
        storeAnswer(this.survey?.id, type);
        this.isSubmit = true;
        setSurvey(this.survey?.id, type);
      }
      this.openModalDeveloping = true;
    },
    closeModalDeveloping() {
      this.openModalDeveloping = false;
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    },
    fetchNewestSurvey() {
      getNewestSurvey().then((res) => {
        this.survey = res.data;
      });
    },
    isAndroid() {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.indexOf('android') > -1;
    },
    isIos() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
  },
});
